import React from "react";
import SEO from "../../components/seo";
import HorizontalLine from "../../components/horizontal-line";
import "../../assets/sass/articles.scss";

const DesignWebsitePl = (props) => {
    return (
        <div location={props.location}>
            <SEO title="Jak stworzyć stronę" language="pl"/>
            <article className="article">
                <div className="container">
                    <div className="article__header">
                        <HorizontalLine classname="white"/>
                        <h1 className="article__header--title">Jak projektować stronę internetową?</h1>
                        <HorizontalLine classname="white"/>
                        <h2 className="article__header--subtitle">Planujesz założenie własnej strony internetowej?
                            Prowadzisz własną firmę i chcesz
                            żeby klienci trafili właśnie do Ciebie? W dzisiejszym wpisie znajdziesz najważniejsze
                            informacje, które oscylują wokół tematyki tworzenia stron internetowych. Przede
                            wszystkim znajdziesz tu porady i wskazówki pomocne przy projektowaniu strony
                            internetowej.<br/> <br/>
                            Dobra, czytelna i responsywna strona to tak naprawdę podstawa każdej firmy;
                            każdego biznesu. I nie ma znaczenia, czy owy biznes jest mały, czy tworzony na
                            większą skalę. To miejsce, do którego śmiało odeślesz swoich klientów i po którym
                            klienci Cię znajdą w wyszukiwarce internetowej. Ale samo tworzenie strony www to
                            nie lada gratka. Zdecydowanie przyda się tu wiedza z psychologii, marketingu i
                            programowania. I porządna strategia.
                        </h2>
                    </div>
                    <h3 className="article__section--title text-center">POWIEDZ TO MIŁYM FONTEM</h3>
                    <p className="article__text">
                        Przeglądarki internetowe dają nam olbrzymie możliwości rozwoju jeśli chodzi o
                        typografię, która gra pierwsze skrzypce w przypadku projektowania stron. Dzisiaj z
                        pewną wrażliwością i fantazją podejdziemy do wyboru komputerowych nośników
                        pisma, czyli fontów. Bowiem wygląd tekstu jest równie ważny, jak jego treść.
                    </p>
                    <ul className="article__list">
                        <li><strong>Webfonty</strong></li>
                    </ul>
                    <p className="article__text">
                        Nadrzędną zasadą, którą należy się kierować przy wybieraniu fontów na witrynę jest
                        upewnienie się, czy skrupulatnie wybrany przez nas krój pisma ma swoją własną
                        wersję webfonta. Czyli czy tekst jest przystosowany do wyświetlania do w Internecie.
                        Preferowany zakres wielkości w przeglądarce to 15-25 pikseli.
                    </p>
                    <ul className="article__list">
                        <li><strong>Polskie znaki</strong></li>
                    </ul>
                    <p className="article__text">
                        Koniecznie zweryfikuj czy wybrany krój pisma posiada litery diakrytyzowane, czyli
                        ściślej mówiąc; polskie znaki. Gdy wykorzystasz krój ich nie posiadający, wygląd
                        tekstu nie będzie estetyczny. Żeby temu zapobiec spróbuj zaznaczyć w filtrach
                        wyszukiwania opcję: “Latin Extended” albo ustawić wyłącznie język polski.
                    </p>
                    <ul className="article__list">
                        <li><strong>Z umiarem</strong></li>
                    </ul>
                    <p className="article__text">
                        Wszelkie niezwykle ozdobne czcionki definitywnie odłóż na bok. Choć dekoracyjne i
                        wyrafinowane litery kuszą oko, pamiętaj, że w przypadku długich tekstów trzebapostawić na
                        czytelność. Jeśli jednak za wszelkę cenę chcesz wykorzystać ozdobny
                        font, ogranicz się wyłącznie do pojedynczych zwrotów bądź nagłówków.
                    </p>
                    <ul className="article__list">
                        <li><strong>Krótkie akapity</strong></li>
                    </ul>
                    <p className="article__text">
                        Badania eyetrackingowe wykazały, że spora część internautów nie czyta treści na
                        stronie od deski do deski, a jedynie skanuje wzrokiem tekst w poszukiwaniu tych
                        informacji, które ich interesują. Podczas projektowania poszczególnych sekcji na
                        stronie staraj się ułatwiać czytelnikom podróż przez witrynę. Unikaj umieszczania
                        długich bloków tekstu. Takie mogą być nieco nużące. Postaw na krótsze fragmenty i
                        stosowanie akapitów.
                    </p>
                    <ul className="article__list">
                        <li><strong>Text align</strong></li>
                    </ul>
                    <p className="article__text">
                        Jednym z ważniejszych kroków jest precyzyjne określenie sposobu wyrównania
                        tekstu. Mamy możliwość wyrównania do lewej, do prawej; wyjustowania lub
                        wyśrodkowania. W przypadku stron responsywnych wyjustowanie może powodować
                        nadmierne przerwy międzywyrazowe, dlatego zalecana jest ostrożność.
                    </p>
                    <h3 className="article__section--title text-center">
                        POMALUJ SWÓJ ŚWIAT - GRAFIKA
                    </h3>
                    <p className="article__text">
                        Teksty to nie jedyna broń jaką powinieneś władać w przypadku tworzenia własnej,
                        wymarzonej strony. Bardzo ważnym aspektem budowania strony są grafiki. Strona
                        internetowa, która jest w związku (stałym!) z estetyką bywa znacznie częściej
                        udostępniana w mediach społecznościowych. Dlatego warto postawić na atrakcyjny
                        design. Mózg dekoduje każde elementy graficzne 60 000 szybciej niż tekst, dlatego
                        nie bój się postawić na zdjęcia, nienachalne animacje, wykresy, ikonki, czy filmy nie
                        zaburzające szybkości strony. Zrezygnuj również ze zdjęć stockowych, ponieważ
                        bardzo łatwo da się wyczuć sztuczność takiej grafiki. Najrozsądniej postawić na
                        zdjęcia wykonane przez profesjonalnego fotografa. <br/> <br/>
                        Tworząc cały projekt graficzny strony internetowej warto pamiętać o kwestii tak
                        standardowych elementów jak logo, czy nawigacja. Tu kluczem do sukcesu jest
                        głęboko przemyślana struktura. Nietypowe rozmieszczenie logo, czy też nad wyraz
                        kreatywne menu to zabieg, na który internauci nie patrzą zbyt pozytywnie. Zadbaj
                        więc o przejrzystość witryny, to znacznie ułatwi prowadzenie odbiorców przez
                        stronę.
                    </p>
                    <h3 className="article__section--title text-center">
                        POSTAW NA MINIMALIZM
                    </h3>
                    <p className="article__text">
                        Flat design, czyli w dosłownym tłumaczeniu “projektowanie płaskie” to
                        ponadczasowy trend i zarazem styl obecny w projektowaniu stron internetowych.
                        Głównym założeniem tego stylu jest wyeliminowanie gradientów, dekoracyjnych
                        elementów i nachalnych animacji a stosowanie wyłącznie prostych krojów pisma i
                        subtelnych kolorów. Płaskie projektowanie doskonale zdaje egzamin w przypadku
                        witryn wyświetlanych na ekranach smartfonów. <br/> <br/>
                        Niezwykła prostota wykonania, ograniczona paleta barw, minimalizm czy proste
                        kształty pozwalają zdobyć skupienie internauty wyłącznie na najważniejszych
                        elementach witryny. Jak płaskie bardzo dobrze wyglądają strony firmowe, wizytówki,
                        czy też aplikacje.
                    </p>
                    <blockquote>
                        <p>
                            Strona internetowa powinna być tworzona z myślą o internautach i na bazie
                            sprawdzonych, doprowadzonych już do perfekcji rozwiązań. Każdą innowacyjną
                            zmianę należałoby sprawdzić za pomocą specjalistycznych testów, które
                            ewentualnie wykażą jej skuteczność. Jeżeli zależy Ci na tym, aby strona
                            doprowadziła do Ciebie klientów i służyła jako sprawny kanał komunikacji - zleć jej
                            stworzenie profesjonalistom.
                        </p>
                    </blockquote>
                </div>
            </article>
        </div>
    );
};

export default DesignWebsitePl;